import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { ForeclosureDownload } from "../components/foreclosure-download"

const ForeClosure = (props) => {
    return (
        <Layout>
            <Seo
                 title="Download This Guide On Stopping Foreclosure - Dahlia Homes"
                location={props.location.pathname}
            />
            <div>
                <ForeclosureDownload />
            </div>
        </Layout>
    )
}
export default ForeClosure