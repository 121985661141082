import React, { Component } from "react"
import { DownloadForm } from "./download-form"
import { StaticImage } from "gatsby-plugin-image"

export class ForeclosureDownload extends Component {
    render() {
        return (
            <div className="bg-dhgray py-10 px-5 lg:px-10">
                <div className="container mx-auto">
                    <div className="lg:grid lg:grid-cols-2">
                        <div className="px-6 leading-8">
                            <h1 className="font-bold text-2xl"><span className="text-dhred">Free Guide:</span> 5 Ways You Can Stop or Avoid Foreclosure In Today’s Market</h1>
                            <p className="pt-2 font-semibold text-xl text-dhred">Learn how to avoid foreclosure, stop the foreclosure process, and your 5 main foreclosure avoidance options with this free guide. </p>
                            <div className="pt-7 pb-5 flex justify-center">
                                <StaticImage src="../images/foreclosure-guide.png" className="flex w-1/2  mx-auto" loading="eager" placeholder="none" title="Dahlia Homes" alt="Dahlia Homes" />
                            </div>
                            <p className="pt-2">After reading this simple to follow 6 page guide you’ll know:</p>
                            <ul className="list-disc ml-10 pt-2">
                                <li>How you may be able to still save your credit during your foreclosure</li>
                                <li>The 5 ways some banks are working with borrowers to lower payments or even sometimes eliminate payments (if you qualify)</li>
                                <li>Why a “Notice of Default” can limit your options (and what to do this week if your bank has already filed a Notice of Default against you)</li>
                                <li>The 5 options to stop a foreclosure that’s already happened</li>
                            </ul>
                            <p className="pt-2">In the end, foreclosure is never fun and doesn’t always end well.</p>
                            <p className="pt-2">But, when you’re armed with the right information, know all of your options, and can make a well educated plan… your odds of coming out of this foreclosure situation with as little long-term damage as possible increase a lot.</p>
                            <p className="pt-2"><strong>Download the Free Foreclosure Guide by entering your information to the right.</strong> We’ll email you the pdf of the guide so you can go through it at your own leisure. You’ll also receive other tips and help from our Foreclosure Avoidance team to help you make a well informed decision.</p>
                            <p className="pt-2">-Dahlia Homes</p>
                        </div>

                        <div className="mx-auto py-4">
                            <div className="flex justify-center">
                                <DownloadForm
                                    formName="Stop Foreclosure Guide Download Page"
                                    formHeader="Request Your FREE Stop Foreclosure Guide Below"
                                    formBody="Just put in your name and email, click 'Submit' and we'll email you the Free Guide right away."
                                    formBodyBottom="We never rent, sell, or share your info with anyone. It's not cool and we hate spam"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}