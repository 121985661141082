import React, { Component } from "react"
import Lock from "../icons/lock"
import { Link } from "gatsby"

export class DownloadForm extends Component {
    render() {
        return (
            <form name={this.props.formName} method="POST" data-netlify="true" className="py-5 lg:py-3 xl:py-0 px-5">
                <input type="hidden" name="form-name" value={this.props.formName} />

                <div className="border bg-gradient-to-r from-dhgreenfrom to-dhgreento rounded-xl sm:w-96 mx-auto md:w-96 h-fit shadow-2xl">
                    <h2 className="font-bold px-5 py-3 text-xl text-white text-center max-w-sm mx-auto">{this.props.formHeader}</h2>
                    <hr className="border" />
                    <div className="bg-white px-5 rounded-b-xl">
                        <p className="text-center max-w-md mx-auto pt-5 max-w-sm mx-auto">{this.props.formBody}</p>
                        <div className="flex justify-center">
                            <div className="px-5 py-7 bg-white bg-opacity-30 rounded-xl">
                                <div className="flex flex-wrap -mx-2">
                                    <div className="w-full px-1">
                                        <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">Name:
                                            <input className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" name="first name" placeholder="Ex: Jane Doe" />
                                        </label>
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-2 mb-6">
                                    <div className="w-full px-1">
                                        <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="email">E-mail:
                                            <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="email" type="email" name="email" required placeholder="Ex: JaneDoe@mail.com" />
                                        </label>
                                    </div>
                                    <p className="text-center pt-5 max-w-xs mx-auto">{this.props.formBodyBottom}</p>
                                </div>
                                <button type="submit" className="mt-6 mb-1 relative h-10 py-2 px-3 mx-auto flex justify-center transition-colors rounded-lg focus:shadow-outline bg-gradient-to-r from-dhgreenfrom to-dhgreento text-white hover:shadow-lg hover:shadow-dhgreen font-semibold">
                                    <span className="text-xs pt-1 sm:pt-0 sm:text-base">Request Guide Now!</span>
                                </button>

                                <div className="pt-5 flex justify-center">
                                    <Link className="flex space-x-1 hover:opacity-70" to="/privacy-policy">
                                        <Lock />
                                        <span className="text-gray-400 text-xs mt-[0.1rem]">We Value Your Privacy</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

